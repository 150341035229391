<template>
  <div>
    <HeaderTit>提示</HeaderTit>
    <div class="infoContainer"> 
      <div class="img">
        <img style="width:150px" src="../assets/img/invoiceSuccess.png" alt="" />
      </div>
      <div class="text2">开票申请提交成功 !</div>
    </div>
  </div>
</template>

<script>
import HeaderTit from '@/components/HeaderTit.vue'
export default {
  components: { HeaderTit }
}
</script>

<style>
.infoContainer{
    width:340px;
    height: 300px;
    margin:100px auto;
}
.img{
    margin:85px
}
.text1{
    margin:20px 120px 
}

.text2{
  font-size: 20px;
    margin:20px 80px 
}
</style>
