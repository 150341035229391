<template>
  <div class="header none-pc">
    <img src="@/assets/img/go.png" v-if="show" class="go" @click="goBack" />
    <span>
      <slot> </slot>
    </span>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "HeaderTit",
  props: {
    show: {
      type: Boolean,
      default: true
    }
  },
  setup() {
    const router = useRouter();
    const goBack = () => {
      router.back();
    };
    return {
      goBack,
    };
  },
});
</script>
<style lang="scss" scoped>
.header {
  width: 100%;
  height: 44px;
  line-height: 44px;
  color: white;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  position: relative;
  background: #45b2ef;
}
.go {
  width: 8px;
  height: 16px;
  position: absolute;
  left: 25px;
  top: 14px;
}
</style>